import Vue from "vue";

/*TITLE*/
document.title = "Alalba | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Alalba";
Vue.prototype.$subtitle = "Una oportunidad para un nuevo espacio de vida";

/*INTRO*/
Vue.prototype.$promoter = "Alalba";
Vue.prototype.$introSubtitle = "Una oportunidad para un nuevo espacio de vida";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_ac5-viena-coglians_193x1383--20230111050110.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo1_bianco-carrara_596x596bianco-carrara_316x90--20230111050156.jpg";
Vue.prototype.$image_bath2 = "banyo-2_vela-natural_limit-vela-natural_marne-wengue--20230111050108.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_ac5-viena-coglians_193x1383--20230111050110.jpg",
  },
  {
    src: "salon_ac5-viena-eisenhut_193x1383--20230111050126.jpg",
  },
  {
    src: "salon_ac5-viena-sadnig_193x1383--20230111050132.jpg",
  },
  {
    src: "salon_ac5-viena-worner_193x1383--20230111050137.jpg",
  },
  {
    src: "salon_forest-arce_22x90--20230111050143.jpg",
  },
  {
    src: "salon_forest-fresno_22x90--20230111050149.jpg",
  },
  {
    src: "salon_forest-natural_22x90--20230111050154.jpg",
  },
  {
    src: "salon_vela-natural_596x596--20230111050158.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo1_bianco-carrara_596x596bianco-carrara_316x90--20230111050156.jpg",
  },
  {
    src: "banyo1_urban-caliza-nature_596x596urban-caliza-nature_316x90bianco-carrara_316x90--20230111050152.jpg",
  },
  {
    src: "banyo1_urban-caliza-nature_596x596bianco-carrara_316x90--20230111050105.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2_vela-natural_limit-vela-natural_marne-wengue--20230111050108.jpg",
  },
  {
    src: "banyo-2_vela-natural_limit-vela-natural_marne-ceniza--20230111050116.jpg",
  },
  {
    src: "banyo-2_vela-natural_limit-vela-natural_marne-blanco--20230111050136.jpg",
  },
  {
    src: "banyo-2_vela-natural_limit-vela-natural_marne-antracita--20230111050144.jpg",
  },
  {
    src: "banyo-2_japan-blanco_japan-antracita_marne-wengue--20230111050150.jpg",
  },
  {
    src: "banyo-2_japan-blanco_japan-antracita_marne-ceniza--20230111050156.jpg",
  },
  {
    src: "banyo-2_japan-blanco_japan-antracita_marne-blanco--20230111050103.jpg",
  },
  {
    src: "banyo-2_japan-blanco_japan-antracita_marne-antracita--20230111050109.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = false;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avenida%20loaysa%20s%20n%20huetor%20vega%2018198%20granada",
    text: "Avenida Loaysa S/N Huetor Vega 18198 Granada",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avenida%20loaysa%20s%20n%20huetor%20vega%2018198%20granada",
    text: "Avenida Loaysa S/N Huetor Vega 18198 Granada",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:682732125",
    text: "682732125",
  },
  {
    icon: "mdi-email",
    link: "mailto:alalbaserrallo@ie-sa.es",
    text: "alalbaserrallo@ie-sa.es",
  },
];
